.about {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* padding: 2rem; */
}

.about__image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    margin-top: 2rem;
}

.about__image img {
    width: 60%;
    height: auto;
}

.about__text {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 550px;
}

.about__heading {
    margin: 2rem 0 2rem;
}

.about__heading h1 {
    font-family: var(--font-family);
    font-size: 32px;
    font-weight: 600;
    line-height: 45px;
    max-width: 510px;
}

.about__content {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    margin: 0 6rem;
    padding-bottom: 3rem;
}

.about__content p {
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: justify;
}

@media screen and (max-width: 1250px) {
    .about {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .about__image {
        margin-bottom: 1rem;
    }

    .about__image img {
        width: 50%;
        height: auto;
    }

}


@media screen and (max-width: 1050px) {

    .about__content {
        margin: 0 3rem;
    }

    .about__content p {
        font-size: 16px;
        line-height: 26px;
    }
    
}

@media screen and (max-width: 650px) {
    .about__content {
        margin: 0 1.5rem;
        padding-bottom: 2rem;
    }

    .about__content p {
        font-size: 14px;
        line-height: 24px;
    }

    .about__heading h1 {
        font-size: 28px;
    }

    .about__image {
        display: none;
    }
}

@media screen and (max-width: 350px) {
    .about__heading h1 {
        font-size: 24px;
    }
    .about__content {
        margin: 0 5px;
    }
    .about__content p {
        font-size: 14px;
    }
}
