.skills {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 8rem;
    margin-bottom: 4rem;
}

.skills__header {
    flex: 1;
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
}

.skills__header button {
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: 500;
    line-height: 4rem;
    padding: 0.05rem 1rem;
    margin: 0 0.8rem 0 0.8rem;
    background-color: var(--color-bg);
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid var(--color-border-before);
    color: var(--color-border-before);
}

.skills__header .active-tab {
    background-color: rgb(15, 15, 15);
    color: var(--color-bg);
}

.skills__header .active-tab:hover {
    background-color: var(--color-bg);
    color: var(--color-border-after);
}

.skills__header button:hover {
    border-color: var(--color-border-after);
    color: var(--color-border-after);
}

.skills__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    /* margin: 0 20rem; */
    width: 60%;
    padding: 10px;
}

@media screen and (max-width: 1440px) {
    .skills__container {
        margin: 0 5rem;
        width: 80%;
    }
}

@media screen and (max-width: 1050px) {
    .skills__container {
        margin: 0 0.5rem;
        width: 100%;
    }

    .skills {
        padding: 0 15px;
    }
}


@media screen and (max-width: 800px) {
    .skills__container {
        grid-template-columns: repeat(2, 1fr);
    }

    .skills__header button {
        font-size: 20px;
    }
}

@media screen and (max-width: 450px) {
    .skills__container {
        grid-template-columns: repeat(2, 1fr);
        gap: 0.8rem;
    }

    .skills__container p {
        display: none;
    }

    .skills__header button {
        font-size: 16px;
        margin: 0 0.2rem;
    }
    .skills {
        padding: 0 10px;
    }

}