.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footer__links {
    margin-bottom: 2rem;
}

.footer__links a {
    margin: 0 1rem;
}

.footer__links-button:hover {
    transform: scale(1.2);
}

.footer__text {
    text-align: center;
    font-family: var(--font-family);
    font-size: 12px;
}