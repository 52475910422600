.projects {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.projects__header h1{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 32px;
    margin-bottom: 1.8rem;
}

.projects__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: 5rem;
    margin-bottom: 5rem;
}

.projects__container-project {
    background-color: #EAE7DD;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);

    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    height: auto;
    padding: 2rem;
    gap: 2rem;
    margin: 0 1rem;
}

.projects__container-project:hover {
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

.projects__container-project-image {
    width: 32rem;
    height: auto;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 20px;
    background-color: var(--color-bg);
    overflow: hidden;
    transition: transform 0.5s;
}

.projects__container-project-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.projects__container-project-image:hover {
    transform: scale(1.08);
    border-radius: 15px;
}

.projects__container-project-text {
    display: flex;
    flex-direction: column;
    width: 25rem;
    height: auto;
}

.projects__container-project-text h3 {
    font-family: var(--font-family);
    margin: 1.5rem 0;
    font-size: 1.1rem;
    font-weight: 600;
}

.projects__container-project-text p {
    font-family: var(--font-family);
    font-size: 1rem;
    margin: 0 1rem 1.3rem 1rem;
    text-align: justify;
}

.projects__container-project-skill {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
}

.projects__container-project-skill p {
    text-align: center;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 600;
    padding: 0.8rem 1rem;
    margin: 0 0.5rem 0.5rem 0.5rem;
}

.projects__container-project-link {
    display: flex;
    justify-content: center;
    margin: 0.9rem 1rem;

}

.projects__container-project-link a {
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: 500;
    line-height: 4rem;
    padding: 0.05rem 0.8rem;
    background-color: var(--color-bg);
    border: none;
    cursor: pointer;
    color: var(--color-border-after);
}

.projects__container-project-link a:hover {
    color: var(--color-button-hover);
}

@media screen and (max-width: 1050px) {
    .projects__container-project {
        flex-direction: column;
        gap: 1rem;
    }

    .project_reverse {
        flex-direction: column-reverse;
    }

    .projects__container-project-text {
        width: 32rem;
        margin: 0 auto;
    }
}

@media screen and (max-width: 650px) {
    .projects__container-project-image {
        width: 25rem;
    }

    .projects__container-project-text {
        width: 25rem;
    }

    .projects__container-project {
        padding: 1rem;
    }

    .projects__container-project-text p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 450px) {
    .projects__container-project-image {
        width: 16rem;
    }

    .projects__container-project-text {
        width: 16rem;
    }

    .projects__container-project-skill {
        gap: 0.15rem;
        flex-wrap: wrap;
    }


}