.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 6rem;
}

.navbar__links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.navbar__links h1 {
    margin-right: 3rem;
    font-family: var(--font-family);
}

.navbar__links-container {
    display: flex;
    flex-direction: row;
}

.navbar__links-container p:hover {
    color: var(--color-button-hover);
}

.navbar__links-container p,
.navbar__menu-container p {
    font-family: var(--font-family);
    color: black;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 2rem;
    cursor: pointer;
}

.navbar__menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.navbar__menu svg {
    cursor: pointer;
}

/* .navbar__menu-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: row;
    flex-basis: 100%;
    text-align: start;
    background: var(--color-footer);
    padding: 1rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0.2);
} */

.navbar__menu-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-basis: 100%;
    text-align: center;
    background: var(--color-footer);
    padding: 0.5rem 0;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 1rem;
    width: 100vw;
    opacity: 0;
    box-shadow: 0 0 1 rgba(0,0,0.2);
}

.navbar__menu-container_links {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.navbar__menu-container p {
    margin: 0 10px;
}

@media screen and (max-width: 1050px) {
    .navbar__links-container {
        display: none;
    }
    .navbar__menu {
        display: flex;
        z-index: 2;
    }

    .navbar {
        padding: 2rem 0 2rem 2rem;
        background-color: var(--color-footer);
    }

    .navbar__menu-container {
        margin-top: 2.5rem;
        padding-left: 1.5rem;
    }
}

@media screen and (max-width: 700px) {
    .navbar {
        padding: 2rem 0 2rem 2rem;
    }

    .navbar__menu-container {
        margin-top: 2.2rem;
        padding-left: 1.5rem;
    }
}

@media screen and (max-width: 550px) {
    .navbar {
        padding: 2rem 0 2rem 2rem;
    }
    .navbar__menu-container {
        margin-top: 2rem;
    }
    .navbar__links h1 {
        font-size: 24px;
    }
    .navbar__menu-container p {
        font-size: 16px;
        margin: 0 8px;
    }
}
