.miniprojects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3.5rem;
}

.miniprojects__header {
    margin-bottom: 2.5rem;
}

.miniprojects__header h2 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 32px;
}

.miniprojects__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto auto;
    gap: 1.5rem;
}

@media screen and (max-width: 600px) {
    .miniprojects__container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 400px) {
    .miniprojects__container {
        grid-template-columns: 1fr;
    }
}