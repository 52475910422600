.skills__container-skill {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-border-before);
    border-radius: 10%;
    max-width: 330px;
}

.skills__container-skill:hover {
    border-color: var(--color-border-after);
}

.skills__container-skill-icon {
    width: 100%;
    height: 100%;
    margin: 1rem 0 2rem 1rem;
}

.skills__container-skill-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap-reverse;

    padding: 1rem 1.5rem;
    height: 100%;
}

.skills__container-skill-content h3 {
    font-family: var(--font-family);
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.9rem;
    margin-bottom: 1rem;
}

.skills__container-skill-content p {
    font-family: var(--font-family);
    font-size: 0.9rem;
    margin-bottom: 0.6rem;
}

@media screen and (max-width: 550px) {
    .skills__container-skill-content {
        padding: 1rem 1rem;
    }
    .skills__container-skill-content h3 {
        font-size: 16px;
        line-height: 25px;
    }
}

@media screen and (max-width: 350px) {
    .skills__container-skill-content h3 {
        font-size: 12px;
    }

    .skills__container-skill-icon {
        margin: 1rem 0 0.5rem 1rem;
    }
}