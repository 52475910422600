.header {
    display: flex;
}

.header__content {
    flex: 0.7;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 1rem;
    padding-left: 6rem;
}

.header__content h1 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 3.3rem;
    line-height: 75px;
    margin-bottom: 1rem;
}

.header__content p {
    font-family: var(--font-family);
    font-size: 1.25rem;
}

.red {
    color: var(--color-button-hover);
}

.header__socials {
    margin-top: 1.4rem;
    display: block !important;
}

.header__socials a {
    margin-right: 0.5rem;
}

.header__socials-btn:hover {
    transform: scale(1.2);
}

.header__image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 900px;
}

.header__image img {
    width: 90%;
    height: 100%;
}

@media screen and (max-width: 1050px) {
    .header {
        flex-direction: column;
    }

    .header__content {
        margin: 0 0 3rem;
    }

    .header__image img {
        width: 70%;
    }
}

@media screen and (max-width: 650px) {
    .header h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .header p {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .header h1 {
        font-size: 34px;
        line-height: 48px;
    }
    .header__content {
        padding-left: 0.1rem;
    }
}