.miniprojects__container-miniproject {
    width: 200px;
    height: 200px;
    display: flex;
    border: 1px solid var(--color-border-after);
    border-radius: 10%;
    cursor: pointer;
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
    max-width: 200px;
    max-height: 200px;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
}

.miniprojects__container-miniproject:hover {
    border-color: var(--color-border-after);
    color: var(--color-border-before);
    background-color: var(--color-border-after);
}

.miniprojects__container-miniproject-title {
    display: flex;
    flex-wrap: wrap;

    padding: 2rem 2rem;
    height: 100%;
}

.miniprojects__container-miniproject-title h3 {
    font-family: var(--font-family);
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    text-align: center;
}

.miniprojects__container-miniproject-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
}



