.contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--color-footer);
}

.contact__image {
    display: flex;
    justify-content: center;
    flex: 0.7;
    max-width: 750px;

}

.contact__image img {
    width: 70%;
    height: auto;
}

.contact__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact__header {
    margin: 2rem 4rem;
}

.contact__header h2 {
    font-family: var(--font-family);
    font-size: 32px;
    font-weight: 500;
}

.contact__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.contact__container-form {
    width: 75%;
}

.contact__container-name,
.contact__container-email,
.contact__container-message {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

}

.contact__container-name label,
.contact__container-email label, 
.contact__container-message label {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 400;
}

.contact__container-name input,
.contact__container-email input,
.contact__container-message textarea {
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid var(--color-border-before);
    background-color: var(--color-bg);
}

.contact__container-message textarea {
    min-height: 100px;
}

.contact__container button {
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: 500;
    line-height: 2.5rem;
    padding: 0.05rem 0.8rem;
    background-color: var(--color-border-after);
    border: 1px solid var(--color-border-after);
    cursor: pointer;
    color: white;
    border-radius: 50px;
    width: 100%;
}

.contact__container button:hover {
    color: var(--color-button-hover);
    transform: scale(1.01);
}

.contact__container-name input:focus,
.contact__container-email input:focus,
.contact__container-message textarea:focus {
    outline: none; /* Remove the default focus outline */
    border-color: var(--color-border-after); /* Change the border color when focused */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
}

@media screen and (max-width: 850px) {
    .contact__image {
        display: none;
    }
}

@media screen and (max-width: 450px) {
    .contact__header h2 {
        font-size: 28px;
    }
}

@media screen and (max-width: 350px) {
    .contact__header {
        margin: 2rem 2rem;
    }
}